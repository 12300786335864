import { createApp } from 'vue'
import App from './App.vue'

// 引入ant ui
import Antd from 'ant-design-vue';
// 引用ant css
import 'ant-design-vue/dist/reset.css';
// 应用全局，全局配置
// 引入vue-router
import Router from "@/router";
// 引入pinia
import { createPinia } from "pinia";
// 引入背景粒子
import VueParticles from 'vue-particles'

createApp(App).use(Router).use(createPinia()).use(Antd).use(VueParticles).mount('#app')
