// Ant Design UI 主题配置文件
import { KicoGet } from "@/config/AxiosConfig.js";

let tokens = {
  // 品牌颜色
  colorPrimary: "#0066f6",
  // "colorPrimary": "#00b8a0",
  // 默认字体大小
  fontSize: 16,
  // 默认圆角
  borderRadius: 3,
  wireframe: false,
  // 默认字体颜色
  colorTextBase: "#2c3e50",
  // 默认浮块背景色
  colorBgBase: "#ffffff",
  // 默认背景色
  colorBgLayout: "#f0f3f8",
  // 默认边框颜色
  colorBorder: "#eee",
  // 默认间距
  sizeStep: 4,
  sizeUnit: 4,
};

KicoGet().then((res) => {});

export default {
  // ant 是以 Design Token去改变主题的
  token: tokens,
};
