// 引入vuerouter的相关包
import { createWebHashHistory, createRouter } from "vue-router";

// 创建实例化对象
const history = createWebHashHistory();
const router = createRouter({
  history,
  routes: [
    {
      name: "登录注册",
      path: "/login",
      component: () => import("@/views/LoginView/LoginView"),
    },
    {
      name: "主页",
      path: "/main",
      redirect: "/work",
      component: () => import("@/views/MainView/MainView"),
      children: [
        {
          name: "控制台",
          path: "/work",
          component: () => import("@/views/WorkView/WorkView"),
        },
        {
          name: "角色管理",
          path: "/roles",
          component: () => import("@/views/RolesView/RolesView"),
        },
        {
          name:"用户界面",
          path: "/users",
          component: () => import("@/views/UsersView/UsersView"),
        },
        {
          name:"部门管理",
          path: "/section",
          component: () => import("@/views/SectionView/SectionView"),
        },
        {
          name:"岗位管理",
          path: "/job",
          component: () => import("@/views/JobView/JobView"),
        }
      ],
    },
  ],
});

// 把router路径暴露出去
export default router;
