/**
 * 这是KICO框架二次封装axios文件
 */

import axios from "axios";

// 常用请求方法 GET POST PUT DELETE
/**
 * 这是封装后的GET请求
 * @param { "这是请求的API" } url
 * @returns body
 */
async function KicoGet(url) {
  let res_data;
  let error_msg;

  await axios
    .get(url)
    .then(function (response) {
      res_data = response.data;
    })
    .catch(function (error) {
      error_msg = error;
    });

  return res_data, error_msg;
}

/**
 * 这是封装后的POST请求
 * @param {"这是请求的API"} url
 * @param {"这是查询分页的数"} parNum
 * @param {"这是raw中传输的json"} parObject
 * @returns
 */
async function KicoPost(url, parObject) {
  // function KicoPost(url, parNum, parObject) {

  let res_data;
  let error_msg;

  await axios
    .post(url, parObject)
    .then(function (response) {
      res_data = response.data;
    })
    .catch(function (error) {
      error_msg = error;
    });

  return { res_data, error_msg };
}

function KicoPut() {
  console.log("空");
}

function KicoDelete() {
  console.log("空");
}

export { KicoGet, KicoPost, KicoPut, KicoDelete };
