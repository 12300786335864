<template>
  <a-config-provider :theme="tokens">
    <a-watermark style="height: 100%;" :content="['HejiuTeam']">
      <!-- 启动 -->
      <router-view></router-view>
    </a-watermark>
  </a-config-provider>
</template>

<script>
import { theme } from 'ant-design-vue';
import token from "@/config/AntThemeConfig"
import { ref, onMounted } from 'vue';
import router from "@/router"

export default {
  name: 'App',
  components: {
  },
  setup() {
    let tokens = ref(token)

    onMounted(() => {
      router.push("/login")
    })

    return {
      theme,
      tokens
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100vh;
  background: #f0f3f8;
}

/* 定义滚动条样式 */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #ffffff00;
}

::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #999;
}


#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
}
</style>
